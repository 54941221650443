import React from "react";
import { Link } from "gatsby";
import styled from "@emotion/styled";
import { css, keyframes } from "@emotion/core";
import tw from "twin.macro";
import mq from "@styles/media";

import { useSiteMetadata } from '../hooks/useMetadata';

import SEO from "../components/SEO/SEO";
import Layout from "../components/Layout";

import Section from "../components/Section";
import Wrapper from "../components/Wrapper";
import Button from "../components/Button";
import Wave from "../components/Wave";
import Image from "../components/Image/Image.Static";
import SubscribeMailchimp from "../components/Subscribe/Subscribe.Mailchimp";
import SocialLinks from "../components/SocialLinks/SocialLinks.Colored";

import Transitions from "../components/Transitions";

import LineSVG from "@images/svg/line.inline.svg";
// import Line from "../components/Line";
import AnimatedServiceSVG from "../components/ServiceSVG";
import AnimatedAboutSVG from "../components/AboutSVG";

import LandingSVG from "@images/svg/landing.inline.svg";
import IntroSVG from "@images/svg/intro.inline.svg";
// import AboutSVG from "@images/svg/about.inline.svg";
// import ServiceSVG from "@images/svg/service.inline.svg";
import MediaSVG from "@images/svg/media.inline.svg";
import SocialSVG from "@images/svg/social.inline.svg";
import WorkSVG from "@images/svg/work.inline.svg";

import CardSVG1 from "@images/svg/girl.inline.svg";
import CardSVG2 from "@images/svg/iphone.inline.svg";
import CardSVG3 from "@images/svg/guy.inline.svg";
import CardSVG4 from "@images/svg/fun.inline.svg";


const IndexPage: React.FC<{}> = () => (
    <Layout>
        <SEO slug="/" title="Brandesen beleállunk" isOrganization isMainPage />
        <Wrap>
            <Transitions.JS.FadeInGroup duration={1000} delay={300} contents>
                <StyledLine />
            </Transitions.JS.FadeInGroup>
            {/* <Line /> */}
            <Section flex hero id="landing">
                <Wrapper left>
                    <Transitions.JS.FadeInUpGroup>
                        <Header><span>Brandesen</span> beleállunk</Header>
                        {/* <Subtitle> */}
                        {/* A legizgalmasabb vállalatok technológiai, branding és marketing partnere */}
                        {/* </Subtitle> */}
                        <Button as={Link} to="/influencers/">Legfrissebb Influenszer Toplistánk!</Button>
                    </Transitions.JS.FadeInUpGroup>
                </Wrapper>
                
                <Transitions.JS.FadeInGroup duration={1000} delay={300} contents removeStylesAfter>
                    <LandingIcon right />
                </Transitions.JS.FadeInGroup>
            </Section>


            <Section flex hero id="about">
                <Wrapper left>
                    <Transitions.JS.FadeInUpGroup>
                        <Title><span>Kik vagyunk mi?</span></Title>
                        <Description>
                            Az iFaktor egy új divízió, mely mögött a 25 éves múlttal rendelkező a HG Media Group áll. A
                            cégcsoport a legnagyobb magántulajdonú tartalom marketing ügynökség a közép-kelet-európai
                            régióban. Portfóliójába több saját magazin és nívós márkamagazin tartozik.
                            <br />
                            A vállalat reklámügynökségi és rendezvényszervezői tevékenységet is folytat. Büszkeségei közé
                            tartozik többek között az Európa legszebb karácsonyi vásárának választott Adventi Ünnep a
                            Bazilikánál rendezvény, illetve a VinCE Budapest. A társalapítóként létrehozott Cathay
                            Communication révén a HG Média Group mára öt kontinens 65 országában van jelen. A csoport több
                            mint két tucat különféle kommunikációs csatornán 8 nyelven nyújt 80 ügyfélnek tartalmat.
                        </Description>
                    </Transitions.JS.FadeInUpGroup>
                </Wrapper>
                <Transitions.JS.FadeInGroup duration={1000} contents>
                    <WorkIcon right />
                </Transitions.JS.FadeInGroup>
            </Section>


            <Section flex hero id="goal">
                <Wrapper right>
                    <Transitions.JS.FadeInUpGroup>
                        <Title><span>Küldetésünk</span></Title>
                        <Description>
                            A digitális korban a márka kommunikáció legnagyobb kihívása,
                            hogy a hagyományos egyirányú vállalati kommunikáció, így a jól ismert
                            reklámformátumok vesztettek a hitelességükből. A fogyasztók egyre inkább
                            hagyatkoznak külső forrásokra márkákkal, termékekkel vagy szolgáltatásokkal
                            kapcsolatos véleményüknek kialakításakor.
                        </Description>
                    </Transitions.JS.FadeInUpGroup>
                </Wrapper>
                <Transitions.JS.FadeInGroup duration={1000} contents>
                    <IntroIcon left />
                </Transitions.JS.FadeInGroup>
            </Section>

            <Section flex hero>
                <Wrapper left>
                    <Transitions.JS.FadeInUpGroup>
                        <Title><span>Küldetésünk</span></Title>
                        <Description>
                        Az interneten a külső források száma pedig végtelen, a megjelent tartalmakat
                        kontrollálni lehetetlen. Az egyetlen megoldás, hogy a hatalmas zajban mi
                        legyünk a leghangosabbak, a legizgalmasabbak és a leginkább meggyőzőek,
                        ebben segít az iFaktor ügyfeleinek.
                        </Description>
                    </Transitions.JS.FadeInUpGroup>
                </Wrapper>
                <Transitions.JS.FadeInGroup duration={1000} contents>
                    <AboutIcon right />
                </Transitions.JS.FadeInGroup>
            </Section>


            {/* <Section id="influencers">
                <Wrapper>
                    <Transitions.JS.FadeInUpGroup duration={1500}>
                        <Title center secondaryAccent><span>Miért az iFaktor?</span></Title>
                        <Description intro>
                            Is this my espresso machine? Wh-what is-h-how did you get my
                            espresso machine? You know what? It is beets. I've crashed into a beet truck
                        </Description>
                    </Transitions.JS.FadeInUpGroup>
                    <Transitions.JS.FadeInZoomGroup as={Grid} delay={100}>
                        <Card>
                            <IconContainer>
                                <CardSVG1 />
                            </IconContainer>
                            <Watermark>1</Watermark>
                            <CardTitle><span>Miért velünk</span></CardTitle>
                        </Card>
                        <Card>
                            <IconContainer>
                                <CardSVG2 />
                            </IconContainer>
                            <Watermark>2</Watermark>
                            <CardTitle tertiaryAccent><span>Miért ne velük</span></CardTitle>
                        </Card>
                        <Card>
                            <IconContainer>
                                <CardSVG3 />
                            </IconContainer>
                            <Watermark>3</Watermark>
                            <CardTitle secondaryAccent><span>Miért mindig csakis velünk</span></CardTitle>
                        </Card>
                    </Transitions.JS.FadeInZoomGroup>
                </Wrapper>
            </Section> */}


            <Section id="szolgaltatasaink">
                <Wrapper>
                    <Transitions.JS.FadeInUpGroup duration={1500}>
                        <Title center secondaryAccent><span>Szolgáltatásaink</span></Title>
                    </Transitions.JS.FadeInUpGroup>
                    <ServiceWrap>
                        <Transitions.JS.FadeInGroup as={ServiceCol} delay={100} interval={100} duration={600}>
                            <ServiceCard bg="#0D6A8C">
                                <ServiceTitle>Közösségi média marketing</ServiceTitle>
                            </ServiceCard>
                            <ServiceCard bg="#107FA8">
                                <ServiceTitle>Influenszer Marketing</ServiceTitle>
                            </ServiceCard>
                            <ServiceCard bg="#118EBB">
                                <ServiceTitle>Grafikai munkák, arculattervezés</ServiceTitle>
                            </ServiceCard>
                            <ServiceCard bg="#0DA3AB">
                                <ServiceTitle>PR-kommunikáció</ServiceTitle>
                            </ServiceCard>
                            <ServiceCard bg="#0B8E96">
                                <ServiceTitle>Médiatervezés és vásárlás</ServiceTitle>
                            </ServiceCard>
                        </Transitions.JS.FadeInGroup>

                        <Transitions.JS.FadeInZoomGroup contents delay={300} duration={1000}>
                            <ServiceIcon />
                        </Transitions.JS.FadeInZoomGroup>
                        
                        <Transitions.JS.FadeInGroup as={ServiceCol} delay={350} interval={100} duration={600}>
                            <ServiceCard bg="#10A89B">
                                <ServiceTitle>Tartalommarketing és natív tartalmak</ServiceTitle>
                            </ServiceCard>
                            <ServiceCard bg="#11BBAD">
                                <ServiceTitle>Employer Branding</ServiceTitle>
                            </ServiceCard>
                            <ServiceCard bg="#13CDBE">
                                <ServiceTitle>Reklámfilmek</ServiceTitle>
                            </ServiceCard>
                            <ServiceCard bg="#44B490">
                                <ServiceTitle>Digitális fejlesztések</ServiceTitle>
                            </ServiceCard>
                            <ServiceCard bg="#62BF86">
                                <ServiceTitle>Rendezvények</ServiceTitle>
                            </ServiceCard>
                        </Transitions.JS.FadeInGroup>
                    </ServiceWrap>
                </Wrapper>
            </Section>

            {/* <Section id="projects">
                <Wrapper>
                    <Transitions.JS.FadeInUpGroup duration={1500}>
                        <Title center secondaryAccent><span>Projektek</span></Title>
                        <Description intro>
                            Is this my espresso machine? Wh-what is-h-how did you get my
                            espresso machine? You know what? It is beets. I've crashed into a beet truck
                        </Description>
                    </Transitions.JS.FadeInUpGroup>
                    
                    <Transitions.JS.FadeInZoomGroup as={FlexGrid}>
                        <FlexCard>
                            <CardImage full gradient color="rgba(13, 106, 140, 1)" colorOpaque="rgba(13, 106, 140, 0)">
                                <ProjectImage src="youtube-1.jpg" />
                            </CardImage>
                            <ProjectContent>
                                <ProjectLead>Kategória</ProjectLead>
                                <ProjectTitle>Projekt 1</ProjectTitle>
                            </ProjectContent>
                        </FlexCard>
                        <FlexCard>
                            <CardImage full gradient color="rgba(11, 142, 150, 1)" colorOpaque="rgba(11, 142, 150, 0)">
                                <ProjectImage src="youtube-2.jpg" />
                            </CardImage>
                            <ProjectContent>
                                <ProjectLead>Kategória</ProjectLead>
                                <ProjectTitle>Projekt 2</ProjectTitle>
                            </ProjectContent>
                        </FlexCard>
                        <FlexCard>
                            <CardImage full gradient color="rgba(16, 168, 155, 1)" colorOpaque="rgba(16, 168, 155, 0)">
                                <ProjectImage src="youtube-3.jpg" />
                            </CardImage>
                            <ProjectContent>
                                <ProjectLead>Kategória</ProjectLead>
                                <ProjectTitle>Projekt 3</ProjectTitle>
                            </ProjectContent>
                        </FlexCard>
                        <FlexCard>
                            <CardImage full gradient color="rgba(68, 180, 144, 1)" colorOpaque="rgba(68, 180, 144, 0)">
                                <ProjectImage src="youtube-4.jpg" />
                            </CardImage>
                            <ProjectContent>
                                <ProjectLead>Kategória</ProjectLead>
                                <ProjectTitle>Projekt 4</ProjectTitle>
                            </ProjectContent>
                        </FlexCard>
                        <FlexCard>
                            <CardImage full gradient color="rgba(98, 191, 134, 1)" colorOpaque="rgba(98, 191, 134, 0)">
                                <ProjectImage src="youtube-5.jpg" />
                            </CardImage>
                            <ProjectContent>
                                <ProjectLead>Kategória</ProjectLead>
                                <ProjectTitle>Projekt 5</ProjectTitle>
                            </ProjectContent>
                        </FlexCard>
                    </Transitions.JS.FadeInZoomGroup>
                </Wrapper>
            </Section> */}

<Section id="influenszerek" style={{paddingBottom: '0px !important', marginBottom: '0px !important'}}>
                <Wrapper>
                    <Transitions.JS.FadeInUpGroup duration={1500}>
                        <Title center secondaryAccent><span>Influenszerek</span></Title>
                        <Description intro>
                        A legizgalmasabb, legérdekesebb híreket olvashatjátok az influenszerekről hírportálunkon.
                        </Description>
                    </Transitions.JS.FadeInUpGroup>
                    <Transitions.JS.FadeInZoomGroup as={Grid} delay={100}>
                        
                        <Anchor
                        target="_blank"
                        as="a"
                        href="https://ifaktor.hu/szep/"
                        rel="noopener nofollow"
                        data-a11y="false"
                        aria-label={`Link to https://ifaktor.hu/szep/`}>
                            <Card>
                                <IconContainer>
                                    <CardSVG1 />
                                </IconContainer>
                                {/* <Watermark>1</Watermark> */}
                                <CardTitle><span>Szép</span></CardTitle>
                            </Card>
                        </Anchor>
                        
                        <Anchor
                        target="_blank"
                        as="a"
                        href="https://ifaktor.hu/inspiralo/"
                        rel="noopener nofollow"
                        data-a11y="false"
                        aria-label={`Link to https://ifaktor.hu/inspiralo/`}>
                            <Card>
                                <IconContainer>
                                    <CardSVG2 />
                                </IconContainer>
                                {/* <Watermark>2</Watermark> */}
                                <CardTitle tertiaryAccent><span>Inspiráló</span></CardTitle>
                            </Card>
                        </Anchor>

                        <Anchor
                        target="_blank"
                        as="a"
                        href="https://ifaktor.hu/erdekes/"
                        rel="noopener nofollow"
                        data-a11y="false"
                        aria-label={`Link to https://ifaktor.hu/erdekes/`}>
                            <Card>
                                <IconContainer>
                                    <CardSVG3 />
                                </IconContainer>
                                {/* <Watermark>3</Watermark> */}
                                <CardTitle secondaryAccent><span>Érdekes</span></CardTitle>
                            </Card>
                        </Anchor>

                        <Anchor
                        target="_blank"
                        as="a"
                        href="https://ifaktor.hu/vicces/"
                        rel="noopener nofollow"
                        data-a11y="false"
                        aria-label={`Link to https://ifaktor.hu/vicces/`}>
                            <Card>
                                <IconContainer>
                                    <CardSVG4 />
                                </IconContainer>
                                {/* <Watermark>4</Watermark> */}
                                <CardTitle secondaryAccent><span>Vicces</span></CardTitle>
                            </Card>
                        </Anchor>
                        
                    </Transitions.JS.FadeInZoomGroup>
                </Wrapper>
            </Section>

            <Section flex hero id="media" style={{ marginTop: '0px !important'}}>
                <Wrapper right>
                    <Transitions.JS.FadeInUpGroup duration={1500}>
                        <Title><span>Minden poszt számít</span></Title>
                        <Description>
                        Elsőként a magyar piacon mi indítottuk el hírportálunkat, amelynek főszereplői az influenszerek.
                        Látogassatok el oldalunkra, hogy értesüljetek a legizgalmasabb, legérdekesebb vagy éppen
                        legviccesebb posztokról, illetve találkozzatok az oldalra készült exkluzív tartalmainkkal!
                        </Description>
                        <Button
                        target="_blank"
                        as="a"
                        href="https://ifaktor.hu/"
                        rel="noopener nofollow"
                        data-a11y="false"
                        aria-label={`Link to https://ifaktor.hu/`}>Érdekel</Button>
                    </Transitions.JS.FadeInUpGroup>
                </Wrapper>
                <Transitions.JS.FadeInGroup duration={1000} contents>
                    <MediaIcon left />
                </Transitions.JS.FadeInGroup>
            </Section>

            {/* <Section id="team">
                <Wrapper>
                    <Transitions.JS.FadeInUpGroup duration={1500}>
                        <Title center secondaryAccent><span>Munkatársaink</span></Title>
                        <Description intro>
                            Is this my espresso machine? Wh-what is-h-how did you get my
                            espresso machine? You know what? It is beets. I've crashed into a beet truck
                        </Description>
                    </Transitions.JS.FadeInUpGroup>
                    
                    <Transitions.JS.FadeInZoomGroup as={TeamGrid}>
                        <TeamCard>
                            <CardImage>
                                <ProjectImage src="team-1.jpg" />
                            </CardImage>
                            <TeamContent>
                                <TeamLead>Beosztás</TeamLead>
                                <TeamTitle>Birta Alíz</TeamTitle>
                                <TeamDescription>Lorem, ipsum dolor sit amet consectetur adipisicing elit. Nam, deleniti!</TeamDescription>
                            </TeamContent>
                        </TeamCard>
                        <TeamCard>
                            <CardImage>
                                <ProjectImage src="team-2.jpg" />
                            </CardImage>
                            <TeamContent>
                                <TeamLead>Beosztás</TeamLead>
                                <TeamTitle>Juhász Katie</TeamTitle>
                                <TeamDescription>Lorem, ipsum dolor sit amet consectetur adipisicing elit. Nam, deleniti!</TeamDescription>
                            </TeamContent>
                        </TeamCard>
                        <TeamCard>
                            <CardImage>
                                <ProjectImage src="team-3.jpg" />
                            </CardImage>
                            <TeamContent>
                                <TeamLead>Beosztás</TeamLead>
                                <TeamTitle>Bognár Ákos</TeamTitle>
                                <TeamDescription>Lorem, ipsum dolor sit amet consectetur adipisicing elit. Nam, deleniti!</TeamDescription>
                            </TeamContent>
                        </TeamCard>
                        <TeamCard>
                            <CardImage>
                                <ProjectImage src="team-4.jpg" />
                            </CardImage>
                            <TeamContent>
                                <TeamLead>Beosztás</TeamLead>
                                <TeamTitle>Hentes János</TeamTitle>
                                <TeamDescription>Lorem, ipsum dolor sit amet consectetur adipisicing elit. Nam, deleniti!</TeamDescription>
                            </TeamContent>
                        </TeamCard>
                    </Transitions.JS.FadeInZoomGroup>
                </Wrapper>
            </Section> */}


            <Section flex>
                <Wrapper left>
                    <Transitions.JS.FadeInUpGroup duration={1500}>
                        <Title><span>Kövess minket!</span></Title>
                        <Icons><SocialLinks links={useSiteMetadata().social} /></Icons>
                    </Transitions.JS.FadeInUpGroup>
                </Wrapper>
                <Transitions.JS.FadeInGroup duration={1000} contents>
                    <SocialIcon right />
                </Transitions.JS.FadeInGroup>
            </Section>


            <SubscribeSection>
                <Wave top waveContext={{animated: false}} />
                <Wrapper>
                    <Transitions.JS.FadeInUpGroup duration={1500}>
                        <Title center>Iratkozz Fel</Title>
                        <SubscribeDescription intro>
                            Ha érdekel mivel foglalkozunk iratkozz fel hírlevelünkre és értesülj a legfrissebb aktualitásunkról!
                        </SubscribeDescription>
                    </Transitions.JS.FadeInUpGroup>

                    <SubscribeMailchimp />
                </Wrapper>
            </SubscribeSection>

            
        </Wrap>
    </Layout>
);

const Wrap = styled.div`
    ${tw`relative w-full h-full`};
`;

const dash = keyframes`
    to {
      stroke-dashoffset: -1000;
    }
`;

const StyledLine = styled(LineSVG)`
    ${tw`absolute hidden w-full h-full md:block`};
    top: 85vh;
    height: calc(100% - 85vh);
    & path {
        animation: ${dash} 30s linear infinite;
        transform: translateZ(0);
        backface-visibility: hidden;
        perspective: 1000;
    }
`;

const spanColorAccent = p => css`
    span {
        color: ${p.theme.colors.accent};
    }
`;

const textCenter = p => css`
    ${p.center && tw`text-center`};
`;

const Header = styled.h1`
    ${tw`mb-4 text-4xl font-semibold leading-none tracking-tight md:text-6xl md:mb-6 lg:mb-10`};
    span {
        color: #35a561;
    }
    // ${spanColorAccent};
`;

const Title = styled.h2<{center?: boolean; secondaryAccent?: boolean}>`
    ${tw`text-3xl font-semibold leading-none tracking-tight md:text-5xl`};
    
    ${textCenter};
    ${spanColorAccent};
    ${p => p.secondaryAccent && `span { color: ${p.theme.colors.secondaryAccent}; }`};
`;

const Subtitle = styled.p`
    ${tw`my-8 text-lg text-gray-700 md:text-xl`};
`;

const Description = styled.p<{intro?: boolean}>`
    ${tw`my-8 text-base leading-7 text-center text-gray-700 md:text-left md:text-lg`};
    
    ${p => p.intro && tw`max-w-xl mx-auto text-center md:text-center`};
`;

const Grid = styled.div`
    ${tw`grid grid-cols-1 gap-8 mt-20 gap-y-20 md:gap-y-16 md:grid-cols-2 xl:grid-cols-4`};
`;

const Card = styled.div`
    ${tw`w-full h-full p-8 hover:z-2 hover:shadow-xl hover:transform hover:-translate-y-2`};
    transition: ${p => p.theme.transitionValue};
`;

const IconContainer = styled.div`
    ${tw`w-full mb-6 z-1`};
    & svg {
        ${tw`max-w-full max-h-full mx-auto`};
        max-height: 8rem;
    }
`;

const Watermark = styled.span`
    ${tw`absolute inset-y-0 left-0 font-bold leading-relaxed text-gray-200 text-20xl`};
`;

const CardTitle = styled.h3`
    ${tw`text-2xl font-semibold leading-none tracking-tight text-center md:text-3xl`};
    ${spanColorAccent};
    ${p => p.secondaryAccent && `span { color: ${p.theme.colors.secondaryAccent}; }`};
    ${p => p.tertiaryAccent && `span { color: ${p.theme.colors.tertiaryAccent}; }`};
`;



const ServiceWrap = styled.div`
    ${tw`relative justify-between w-full h-full md:mt-16 md:flex`};
`

const ServiceCol = styled.div`
    ${tw`w-full py-8 md:w-1/2 lg:w-1/3 md:first-of-type:mr-4 md:last-of-type:ml-4`};
`;

const ServiceCard = styled.div<{bg?: string}>`
    ${tw`w-full p-6 py-8 my-6 rounded shadow-md z-2 `};
    background-color: ${p => p.bg};
`;

const ServiceTitle = styled(CardTitle)`
    ${tw`text-lg font-bold tracking-normal text-white uppercase md:text-xl`};
`;



const FlexGrid = styled.div`
    ${tw`flex flex-wrap`};
`;

const FlexCard = styled.div`
    ${tw`flex items-end flex-1 m-3 rounded-md shadow-xl md:m-6 hover:shadow-2xl hover:transform hover:-translate-y-1`};
    transition: ${p => p.theme.transitionValue};
    min-width: 250px;
    min-height: 250px;
    max-height: 300px;
`;

const CardImage = styled.div<{full?: boolean; gradient?: boolean; color?: string; colorOpaque?: string}>`
    ${tw`w-full h-56 overflow-hidden rounded-t-md`};
    ${p => p.full && tw`absolute h-full rounded-md`};
    
    &:before, &:after {
        ${p => p.gradient && tw`absolute bottom-0 left-0 block w-full h-full rounded-md z-1`};
    }
    ${p => p.gradient && `
        &:after {
            content: '';
            opacity: 1;
            background: linear-gradient(to bottom, ${p.colorOpaque} 0%, ${p.color} 90%);
        }
    `};
`;

const ProjectImage = styled(Image)`
    ${tw`w-full h-full min-w-full min-h-full`};
`;

const ProjectContent = styled.div`
    ${tw`w-full p-6 py-8 mx-auto my-0 text-white z-2`};
`;

const ProjectLead = styled.span`
    ${tw`text-base font-semibold leading-relaxed tracking-normal text-gray-400 uppercase md:text-base`};
`;

const ProjectTitle = styled.h3`
    ${tw`text-3xl font-bold leading-none tracking-normal uppercase md:text-4xl`};
`;



const TeamGrid = styled(Grid)`
    ${tw`gap-8 mt-0 md:gap-8 md:grid-cols-2 lg:grid-cols-4`};
`;

const TeamCard = styled(Card)`
    ${tw`rounded-md shadow-xl hover:shadow-2xl hover:transform hover:-translate-y-2`};
    background-color: ${p => p.theme.colors.card};
    transition: ${p => p.theme.transitionValue};
`;

const TeamContent = styled.div`
    ${tw`w-full p-4 py-6 mx-auto my-0 md:p-6 z-2`};
`;

const TeamLead = styled.span`
    ${tw`inline px-2 py-1 text-xs font-semibold tracking-normal text-white uppercase bg-blue-500 rounded-full`};
`;

const TeamTitle = styled(ProjectTitle)`
    ${tw`mt-3 text-xl normal-case md:text-2xl`};
`;

const TeamDescription = styled(Description)`
    ${tw`m-0 mt-3 text-sm leading-normal text-left text-gray-700 md:text-sm lg:text-base`};
`;



const SubscribeSection = styled(Section)`
    ${tw`pt-2 mt-16 text-white sm:pt-2 md:pt-4 lg:pt-6 xl:pt-8 sm:mt-20 md:mt-24 lg:mt-32 xl:mt-40`};
    background: ${p => p.theme.colors.accent};
`;

const SubscribeDescription = styled(Description)`
    ${tw`text-green-100`};
`;



const Icons = styled.div`
    ${tw`w-full my-4`};
    svg {
        min-width: 3rem;
        min-height: 3rem;
        max-width: 3rem;
        max-height: 3rem;
    }
`;

const Anchor = styled.a``;



const HeroSVG = css`
    ${tw`w-full h-full max-w-full md:absolute md:w-7/12`};
`;

const ContentSVG = css`
    ${tw`w-full h-full max-w-full md:absolute md:w-6/12`};
`;

const RightSVG = css`
    ${tw`md:right-0`};
`;

const LeftSVG = css`
    ${tw`md:left-0`};
`;

const LandingIcon = styled(LandingSVG)<{left?: boolean; right?: boolean}>`
    ${HeroSVG};

    top: 70px;
    ${mq.from_tablet`
        max-height: calc(100% - 70px);
    `};
    
    ${p => p.left && LeftSVG};
    ${p => p.right && RightSVG};
`;

const IntroIcon = styled(IntroSVG)<{left?: boolean; right?: boolean}>`
    ${ContentSVG};
        
    ${p => p.left && LeftSVG};
    ${p => p.right && RightSVG};
`;

// const AboutIcon = styled(AboutSVG)<{left?: boolean; right?: boolean}>`
const AboutIcon = styled(AnimatedAboutSVG)<{left?: boolean; right?: boolean}>`
    ${ContentSVG};
            
    ${p => p.left && LeftSVG};
    ${p => p.right && RightSVG};
`;

const MediaIcon = styled(MediaSVG)<{left?: boolean; right?: boolean}>`
    ${ContentSVG};
            
    ${p => p.left && LeftSVG};
    ${p => p.right && RightSVG};
`;

const SocialIcon = styled(SocialSVG)<{left?: boolean; right?: boolean}>`
    ${ContentSVG};
            
    ${p => p.left && LeftSVG};
    ${p => p.right && RightSVG};
`;

const WorkIcon = styled(WorkSVG)<{left?: boolean; right?: boolean}>`
    ${ContentSVG};
            
    ${p => p.left && LeftSVG};
    ${p => p.right && RightSVG};
`;

// const ServiceIcon = styled(ServiceSVG)`
const ServiceIcon = styled(AnimatedServiceSVG)`
    ${ContentSVG};
    ${tw`md:inset-0 md:h-full md:min-h-full md:w-full md:min-w-full md:opacity-50 lg:opacity-100`};
`;

export default IndexPage;